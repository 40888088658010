a {
    color: white;
    text-decoration: none;
}
.navbar {
    background-color: #333;
    overflow: hidden;
}     
.logo {
    max-width: 50px;
    margin-right: 5px;
}